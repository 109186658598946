import { getNumber } from '@util/createListingHelpers';
import { FirebaseCallable, db, functions } from '@util/firebase';
import { client } from '@util/getTypesense';
import {
  getCdnImageUrls,
  getStoragePathFromCdnUrl,
  slugify,
} from '@util/urlHelpers';
import { collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from 'firebase/storage';

import { ProductDocument } from 'models/product';
import { UserDocument } from 'models/user';
import { UseFormSetError } from 'react-hook-form';
import { removeUndefinedRecursive } from '../products';
import { RideType } from 'models/shared';

const storage = getStorage();

export async function uploadListingImage({
  data,
  userId,
  productId,
}: {
  data: string;
  userId: string;
  productId: string;
}) {
  const path = `${userId}/products/${productId}/${
    Date.now() + Math.random().toFixed(5).replace('.', '')
  }`;
  const listingImagesRef = ref(storage, path);
  // const res = await uploadBytes(listingImagesRef, data);
  if (!data.startsWith('data')) data = 'data:image/jpeg;base64,' + data;
  const res = await uploadString(listingImagesRef, data, 'data_url');
  const download_url = await getDownloadURL(res.ref);
  return {
    remotePath: res.metadata.fullPath,
    download_url,
  };
}

export async function deleteListingImage(full: string, thumb: string) {
  const pathsWithoutCdn = getStoragePathFromCdnUrl({
    full,
    thumb,
  });
  const { full: fullPath, thumb: thumbPath } = pathsWithoutCdn;
  [fullPath, thumbPath].forEach(async (path) => {
    const listingImagesRef = ref(storage, path);
    deleteObject(listingImagesRef);
  });
}

export async function uploadUserImage(data: Blob, userId: string) {
  const path = `${userId}/avatar-${Date.now()}`;
  const userImagesRef = ref(storage, path);
  const res = await uploadBytes(userImagesRef, data);
  return res.metadata.fullPath;
}

export async function uploadStoreImage(data: Blob, userId: string) {
  const path = `${userId}/store-${Date.now()}`;
  const storeImageRef = ref(storage, path);
  const res = await uploadBytes(storeImageRef, data);
  return res.metadata.fullPath;
}

export async function uploadGarageImage(data: Blob, userId: string) {
  const path = `${userId}/bikes/${Date.now()}`;
  const storeImageRef = ref(storage, path);
  const res = await uploadBytes(storeImageRef, data);
  return res.metadata.fullPath;
}

export async function uploadReviewImage(data: string, review_id: string) {
  const path = `reviews/${review_id}/${Math.random()
    .toString(36)
    .substring(7)}`;
  const reviewImageRef = ref(storage, path);
  if (!data.startsWith('data')) data = 'data:image/jpeg;base64,' + data;
  const res = await uploadString(reviewImageRef, data, 'data_url');
  const download_url = await getDownloadURL(res.ref);
  return {
    remotePath: res.metadata.fullPath,
    download_url,
  };
}

export async function uploadOtherImage(data: string, userId: string) {
  const path = `${userId}/other/${Date.now()}`;
  const otherImagesRef = ref(storage, path);
  if (!data.startsWith('data')) data = 'data:image/jpeg;base64,' + data;
  const contentType = data.split(';')[0].split(':')[1]; // may not be needed
  const res = await uploadString(otherImagesRef, data, 'data_url', {
    contentType,
  });
  const download_url = await getDownloadURL(res.ref);
  return {
    remotePath: res.metadata.fullPath,
    download_url,
  };
}

export async function markSellerListingsAsDraft(uid: string) {
  // https callable function to mark all listings as draft
  await httpsCallable<{ uid: string }>(
    functions,
    FirebaseCallable.markSellerListingsAsDraft
  )({ uid });
}

export async function exportListings({ userDoc }: { userDoc: UserDocument }) {
  try {
    // get products from typesense
    const products = [];
    let more = true;
    let page = 1;
    while (more) {
      const res = await client
        .collections<ProductDocument>('products_alias')
        .documents()
        .search({
          filter_by: `seller_id:=${userDoc.uid}`,
          query_by: 'title',
          q: '*',
          page,
          per_page: 250,
        });
      if (res.hits?.length) {
        products.push(...res.hits);
        page++;
      } else {
        more = false;
      }
    }
    // write to csv

    const headers = [
      'Title',
      'Sku',
      'Category',
      'Sub Category',
      'Brand',
      'Condition',
      'Size',
      'Stock',
    ];
    let mappedProducts: any[] = [];
    products.forEach((product) => {
      mappedProducts.push([
        product.document.title,
        product.document.sku,
        product.document.category,
        product.document.category1,
        product.document.brand,
        product.document.condition,
        product.document.size,
        product.document.stock,
      ]);
      if (product.document.has_variations) {
        product.document.variations!.forEach((variation) => {
          mappedProducts.push([
            '',
            variation.sku ?? '',
            '',
            '',
            '',
            '',
            variation.size,
            variation.qty,
          ]);
        });
      }
    });

    // Convert array to CSV
    let csvContent = headers.join(',') + '\n';
    csvContent += mappedProducts.map((e) => e.join(',')).join('\n');

    // Create a blob of the data
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    // Create a link and simulate a click to download the file
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'export.csv';

    document.body.appendChild(a);
    a.click();

    // Remove the link and free memory
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (err: any) {
    console.error(err);
  }
}

export interface VideoListingsPermissions {
  allow_all: boolean;
  uids: string[];
}
export const getVideoListingPermissions = async () => {
  const colRef = collection(db, 'other');
  const docRef = doc(colRef, 'video_listings');
  const snap = await getDoc(docRef);
  const data = snap.data() as VideoListingsPermissions | null;
  return data;
};

export const updateVideoListingsPermissions = async ({
  uid,
  remove,
}: {
  uid: string;
  remove?: boolean;
}) => {
  const colRef = collection(db, 'other');
  const docRef = doc(colRef, 'video_listings');
  const snap = await getDoc(docRef);
  const data = snap.data() as VideoListingsPermissions | null;
  if (data) {
    if (remove) {
      data.uids = data.uids.filter((id) => id !== uid);
    } else {
      data.uids.push(uid);
    }
    await updateDoc(docRef, { uids: data.uids });
  }
};

export function createListingFormToProductDocument(
  form: ProductDocument,
  userDoc: UserDocument,
  variant: 'create' | 'edit',
  productId: string
): ProductDocument {
  if (!userDoc) throw new Error('User doc is not defined');
  const product: ProductDocument = {
    ...form,
    country_code:
      userDoc.addresses.find((a) => a.is_shop_location)?.country_code ||
      form.shipment?.ship_from.country_code ||
      'US',
    price: getNumber(form.price), // FormCurrencyInput returns price as a string
    minimum_offer_price: getNumber(form.minimum_offer_price), // same as above
    thumbnail: form.images?.[0]?.thumb ?? '',
    out_of_stock:
      (form.is_auction && form.start_time! >= Date.now()) ||
      form.stock <= 0 ||
      (form.has_variations && !!form.variations?.every((v) => v.qty <= 0)),
    ...(variant === 'create' && {
      slug: slugify(form.title, productId),
      seller_id: userDoc.uid,
      account_id: userDoc.account_id,
      og_price: getNumber(form.price),
      id: productId,
      seller_type: userDoc.seller_type ?? 'individual',
      is_featured: false,
    }),
  };
  if (
    product.category === 'Bikes' &&
    product.ride_types.includes('Dirt Bikes')
  ) {
    product.category = 'Dirt Bikes'; // legacy
  }
  if (!product.on_sale) {
    product.price = product.og_price;
  }
  if (product.condition !== 'New') {
    product.has_variations = false;
    delete product.variations;
  }
  if (product.has_variations && product.variations?.length) {
    if (product.category2 === 'Gear Combos') {
      const letterSizeVaritaions = product.variations.filter(
        (v) => !v.is_number
      );
      const numberSizeVaritaions = product.variations.filter(
        (v) => v.is_number
      );
      // price is the minimum combo price of the two variations
      const minLetterPrice = Math.min(
        ...(letterSizeVaritaions.map((v) => v.price) ?? [0])
      );
      const minNumberPrice = Math.min(
        ...(numberSizeVaritaions.map((v) => v.price) ?? [0])
      );
      product.price = minLetterPrice + minNumberPrice;
    } else {
      product.price = Math.min(
        ...(form.variations?.map((v) => v.price) ?? [0])
      );
    }
    if (!product.on_sale) {
      product.og_price = product.price;
    }
  }
  if (!product.is_auction) {
    delete product.start_time;
    if (!product.curated_lists?.includes('Flash Deals')) {
      delete product.end_time;
    }
  } else {
    product.is_flat_rate = true;
    product.is_auction_live = product.is_auction_live ?? false;
  }
  // remove fields that are not needed for certain categories
  if (product.is_flat_rate) {
    delete product.shipment;
    // sort shipping costs from app
    // { name: "Continental US", code: "US", enabled: true },
    // { name: "Alaska", code: "AK", enabled: true },
    // { name: "Hawaii", code: "HI", enabled: true },
    // { name: "Puerto Rico", code: "PR", enabled: true },
    // { name: "Mexico", code: "MX", enabled: true },
    // { name: "Canada", code: "CA", enabled: true },
    // { name: "Europe", code: "EU", enabled: true },
    // { name: "Australia", code: "AU", enabled: true },
    // { name: "South America", code: "SAM", enabled: true },
    // { name: "Other", code: "OT", enabled: true },
    const sortOrder = [
      'US',
      'AK',
      'HI',
      'PR',
      'MX',
      'CA',
      'EU',
      'AU',
      'SAM',
      'OT',
    ];
    product.shipping_costs = product.shipping_costs?.sort(
      (a, b) => sortOrder.indexOf(a.code) - sortOrder.indexOf(b.code)
    );
  }
  if (product.category1 === 'Gear') {
    if (product.category2 === 'Jerseys') {
      delete product.size?.number;
      if (product.has_variations) {
        product.variations = product.variations?.filter((v) => !v.is_number);
      }
    }
    if (product.category2 === 'Pants') {
      delete product.size?.letter;
      if (product.has_variations) {
        product.variations = product.variations?.filter((v) => v.is_number);
      }
    }
  }
  if (product.has_variations) {
    delete product.size;
  } else {
    delete product.variations;
  }
  if (product.category === 'Dirt Bikes') {
    delete product.fitting_bikes;
    delete product.shipment;
    delete product.shipping_costs;
    product.category2 = form.make ?? null;
    product.category3 = slugify(`${form.year}-${form.make}-${form.model}`);

    if (product.bike_package === 2) {
      product.bumps = 1;
      product.date_featured = Date.now();
      product.is_featured = true;
    } else if (product.bike_package === 3) {
      product.bumps = 2;
      product.date_featured = Date.now();
      product.is_featured = true;
    }
  } else {
    delete product.bike_package;
    delete product.bike_package_ch;
  }
  if (product.category === 'Experiences') {
    delete product.shipment;
    delete product.shipping_costs;
    product.category1 = 'Experience';
  }
  if (product.category === 'Giveaways') {
    delete product.shipment;
    product.is_digital = true;
    product.is_flat_rate = true;
    product.shipping_costs = [
      {
        cost: 0,
        code: 'OT',
        name: 'Other',
        enabled: true,
      },
    ];
  }
  if (product.category === 'Bike Parts' || product.category === 'Dirt Bikes') {
    delete product.size;
  }
  if (product.category === 'Bike Parts' && product.fitting_bikes?.universal) {
    product.fitting_bikes.bikes = [];
  }
  if (product.category === 'Collectibles' && !product.ride_types?.length) {
    product.ride_types = ['Dirt Bikes'];
  }

  product.categories = [
    product.category,
    product.category1,
    product.category === 'Dirt Bikes' ? product.make : product.category2,
    product.category === 'Dirt Bikes'
      ? slugify(`${product.year}-${product.make}-${product.model}`)
      : product.category3,
  ].filter(Boolean) as string[]; // only take defined values

  if (product.og_price < product.price) {
    product.og_price = product.price;
  }
  // if accessories, and its one of the shared categories, ride types should be all off road
  if (
    product.category === 'Accessories' &&
    ['Dirt Bikes', 'ATV', 'UTV'].some((type) =>
      product.ride_types.includes(type as RideType)
    ) &&
    [
      'Fluids & Lubrication',
      'Gear Bags',
      'Nutrition & Hydration',
      'Tie Downs & Straps',
      'Watches & Wearables',
    ].some((cat) => product.category1 === cat)
  ) {
    product.ride_types = ['Dirt Bikes', 'ATV', 'UTV'];
  }
  removeUndefinedRecursive(product);
  return product;
}

export function validateProduct(
  product: ProductDocument,
  setError: UseFormSetError<ProductDocument>
) {
  const errors = new Set<string>();
  if (product.on_sale && product.price > product.og_price * 0.99) {
    setError('price', {
      type: 'manual',
      message: 'Sale price must be at least 1% less than original price',
    });
    errors.add('product-price-and-inventory');
  }
  if (product.category === 'Dirt Bikes') {
    if (!product.location) {
      setError('location', {
        type: 'manual',
        message: 'Location is required for dirt bikes',
      });
      errors.add('bike-info');
    }
    if (!product.year) {
      setError('year', {
        type: 'manual',
        message: 'Year is required for dirt bikes',
      });
      errors.add('bike-info');
    }
    if (!product.make) {
      setError('make', {
        type: 'manual',
        message: 'Make is required for dirt bikes',
      });
      errors.add('bike-info');
    }
    if (!product.model) {
      setError('model', {
        type: 'manual',
        message: 'Model is required for dirt bikes',
      });
      errors.add('bike-info');
    }
  }
  if (product.category !== 'Dirt Bikes' && !product.brand) {
    setError('brand', {
      type: 'manual',
      message: `Brand is required for ${product.category}`,
    });
    errors.add('product-info');
  }
  if (product.category1 === 'Gear' && !product.category2) {
    setError('category2', {
      type: 'manual',
      message: 'Sub category is required for gear',
    });
    errors.add('product-info');
  }
  if (product.category2 === 'Gear Combos' && product.condition === 'Used') {
    if (!product.size?.letter || !!!product.size?.number) {
      setError(!product.size?.letter ? 'size.letter' : 'size.number', {
        type: 'manual',
        message: 'Size is required for gear combos',
      });
      errors.add('product-info');
    }
  }
  if (product.category2 === 'Jerseys' && product.condition === 'Used') {
    if (!product.size?.letter) {
      setError('size.letter', {
        type: 'manual',
        message: 'Size is required for jerseys',
      });
      errors.add('product-info');
    }
  }
  if (product.category2 === 'Pants' && product.condition === 'Used') {
    if (!product.size?.number) {
      setError('size.number', {
        type: 'manual',
        message: 'Size is required for pants',
      });
      errors.add('product-info');
    }
  }
  if (
    !['Collectibles', 'Giveaways'].includes(product.category) &&
    !product.category1
  ) {
    setError('category1', {
      type: 'manual',
      message: 'Category is required',
    });
    errors.add('product-info');
  }
  if (
    product.category === 'Bike Parts' &&
    !product.fitting_bikes?.universal &&
    (!product.fitting_bikes || product.fitting_bikes.bikes.length === 0) &&
    !product.ride_types.includes('Cycling') &&
    !product.ride_types.includes('Snow')
  ) {
    setError('fitting_bikes', {
      type: 'manual',
      message: 'Fitting bikes is required for bike parts',
    });
    errors.add('bike-fitment');
  }
  if (product.has_variations) {
    if (!product.variations || product.variations.length === 0) {
      setError('variations', {
        type: 'manual',
        message: 'At least one variation is required',
      });
      errors.add('gear-fitment');
    }
    if (product.variations?.some((v) => !v.price)) {
      setError('variations', {
        type: 'manual',
        message: 'All variations must have a price',
      });
      errors.add('product-price-and-inventory');
    }
    if (product.variations?.some((v) => !v.size)) {
      setError('variations', {
        type: 'manual',
        message: 'All variations must have a size',
      });
      errors.add('gear-fitment');
    }
    if (product.category2 === 'Gear Combos') {
      // must have a letter size and a number size
      const hasLetterSize = product.variations?.some((v) => !v.is_number);
      const hasNumberSize = product.variations?.some((v) => v.is_number);
      if (!hasLetterSize || !hasNumberSize) {
        setError('variations', {
          type: 'manual',
          message: 'Gear combos must have a jersey size and a pants size',
        });
        errors.add('gear-fitment');
      }
    }
  }
  if (product.is_auction && product.start_time) {
    // if auction start date is before the product date added then throw an error
    const startDate = new Date(product.start_time);
    const dateAdded = product.date_added
      ? new Date(product.date_added)
      : new Date();
    if (startDate < dateAdded) {
      setError('start_time', {
        type: 'manual',
        message: 'Auction start date must be in the future',
      });
      errors.add('product-price-and-inventory');
    }
  }
  if (product.is_flat_rate && product.shipping_costs?.length === 0) {
    setError('shipping_costs', {
      type: 'manual',
      message:
        'At least one shipping region is required for flat rate shipping',
    });
    errors.add('shipping');
  }
  if (
    product.is_flat_rate &&
    product.shipping_costs?.some((c) => isNaN(c.cost))
  ) {
    setError('shipping_costs', {
      type: 'manual',
      message: 'All enabled shipping regions must have a cost',
    });
    errors.add('shipping');
  }
  if (
    product.is_flat_rate &&
    product.shipping_costs?.some((c) => c.cost > 1500)
  ) {
    setError('shipping_costs', {
      type: 'manual',
      message: 'Shipping cost cannot exceed $1500',
    });
    errors.add('shipping');
  }
  if (product.price > 100_000) {
    setError('price', {
      type: 'manual',
      message: 'Price cannot exceed $100,000',
    });
    errors.add('product-price-and-inventory');
  }
  if (
    product.is_flat_rate === false &&
    product.shipment?.package.insured_value?.amount &&
    product.shipment.ship_from.country_code !== 'US'
  ) {
    // insurance is not available for international shipments
    setError('shipment.package.insured_value', {
      type: 'manual',
      message: 'Shipping insurance is only available for domestic shipments',
    });
    errors.add('shipping');
  }
  if (product.is_flat_rate === false) {
    if (
      product.shipment?.package.weight.value &&
      product.shipment?.package.weight.value > 150 * 16
    ) {
      setError('shipment.package.weight', {
        type: 'manual',
        message: 'Weight cannot exceed 150 pounds',
      });
      errors.add('shipping');
    }
    if (
      product.shipment?.package.dimensions.length &&
      product.shipment?.package.dimensions.length > 119
    ) {
      setError('shipment.package.dimensions.length', {
        type: 'manual',
        message: 'Length cannot exceed 119 inches',
      });
      errors.add('shipping');
    }
    if (
      product.shipment?.package.dimensions.width &&
      product.shipment?.package.dimensions.width > 80
    ) {
      setError('shipment.package.dimensions.width', {
        type: 'manual',
        message: 'Width cannot exceed 80 inches',
      });
      errors.add('shipping');
    }
    if (
      product.shipment?.package.dimensions.height &&
      product.shipment?.package.dimensions.height > 70
    ) {
      setError('shipment.package.dimensions.height', {
        type: 'manual',
        message: 'Height cannot exceed 70 inches',
      });
      errors.add('shipping');
    }
  }
  if (errors.size > 0) {
    // throw the first error
    console.error(errors);
    throw new Error(errors.values().next().value);
  }
}
